var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-app-bar",
        {
          attrs: {
            app: "",
            flat: "",
            color: "white",
            light: "",
            "extension-height": "64",
          },
          scopedSlots: _vm._u([
            {
              key: "extension",
              fn: function () {
                return [
                  _c(
                    "v-toolbar",
                    { attrs: { color: "white", flat: "", light: "" } },
                    [
                      _c("v-container", [
                        _c(
                          "div",
                          _vm._l(Object.keys(_vm.navBarLinks), function (link) {
                            return _c(
                              "v-btn",
                              {
                                key: link,
                                attrs: { text: "" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onClickMenuCategory(link)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(link))]
                            )
                          }),
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "div",
            [
              _c("v-img", {
                staticClass: "xlogo shrink mr-2",
                attrs: {
                  alt: "NEW Energyview",
                  contain: "",
                  src: require("@/assets/NEW_Energie_Logo_RBG_2023.svg"),
                },
              }),
            ],
            1
          ),
          _c("v-spacer"),
          _vm.getMode() == _vm.test
            ? _c("div", { staticStyle: { "background-color": "red" } }, [
                _vm._v("TEST"),
              ])
            : _vm._e(),
          _c("div", { staticClass: "mr-4" }, [
            _vm._v(_vm._s(_vm.getUsermailAddr)),
          ]),
          _c("div", { attrs: { id: "loginnew-dropdown" } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }