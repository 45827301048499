import Vue from "vue";
import { firestore } from "@/plugins/firebase";
import {
    doc,
    setDoc,
    deleteDoc,
    collection,
    query,
    where,
    getDocs,
    onSnapshot
} from "firebase/firestore";
export default {
    state: {
        isLoading: false,
        firestoreListener: null,
        firestoreZeitbereichListener: null,
        firestoreTranchenListener: null,
        alleProduktnamen: [],
        vertraege: {},
        vertragsnummern: {},
        vertragsnehmer: {}
    },
    mutations: {},
    actions: {
        async attachVertragsnummern({ state }, { docid }) {
            return new Promise(async (resolve) =>{
                let tempVar={}
                console.log("attachVertragsnummern",docid);
                state.isLoading = true;
                const vertragsnummernRef = collection(firestore, "Stammdaten");
                const q = query(vertragsnummernRef, where("vertragsart", "==", docid));
                const vertragsnummernSnapshot = await getDocs(q);
                console.log(vertragsnummernSnapshot.docs)
                for (const vn of vertragsnummernSnapshot.docs) {
                    const _data = vn.data();
                    tempVar[_data.vertragsnummer]=0;
                    console.log(_data.vertragsnehmer.name)
                    state.vertragsnehmer[_data.vertragsnummer] = _data.vertragsnehmer.name
                }
                state.isLoading = false;
                state.vertragsnummern[docid]=Object.keys(tempVar)
                //resolve(state.vertragsnummern);
                resolve(state)
            })
        },
        async attachVertraege({ state }) {
            return new Promise((resolve) => {
                if (state.firestoreListener) {
                    resolve(state.value);
                    return;
                }
                state.isLoading = true;

                const vertraegeRef = collection(firestore, "Komfortbewirtschaftung", "Stammdaten", "Vertraege");
                //const q = query(vertraegeRef, where("konfiguriert", "==", false));
                const q = query(vertraegeRef);
                state.firestoreListener =
                    onSnapshot(q, { includeMetadataChanges: true }, (snapshot) => {
                        for (const doc of snapshot.docs) {
                            if (doc.exists) {
                                const _data = doc.data();
                                _data.zeitbereiche = {};
                                _data.tranchen = {};
                                Vue.set(state.vertraege, doc.id, _data);
                            }
                        }

                        state.isLoading = false;
                        resolve(state.vertraege);
                    })
            })
        },
        async insertZeitbereich({ state, commit }, { item, zeitbereich }) {
            console.log(state, commit);
            console.log("insertZeitbereich", item);
            const data = {
                bereiche: {},
                delta: 0,
                value: 999,
                id: zeitbereich,
                vertrag: item
            }
            const docRef = doc(firestore, `/Komfortbewirtschaftung/Stammdaten/Vertraege/${item}/zeitbereiche/${zeitbereich}`);
            try {
                await setDoc(docRef,
                    data
                )
                // commit('showSnackbar', { message: 'Eintrag gespeichert', status: 'success' }, { root: true })
            } catch (error) {
                console.error(error);
                /*this.$store.commit("showSnackbar", {
                    status: "error",
                    message: error.message,
                });*/
            }
        },
        async updateVertragsprodukt({ state, commit }, { item, value }) {
            console.log(state, commit);
            console.log("insertZeitbereich", item);
            const docRef = doc(firestore, `/Komfortbewirtschaftung/Stammdaten/Vertraege/${item}`);
            const data = {
                product: value
            }
            try {
                await setDoc(docRef, data, { merge: true })
                // commit('showSnackbar', { message: 'Eintrag gespeichert', status: 'success' }, { root: true })
            } catch (error) {
                console.error(error);
                /*this.$store.commit("showSnackbar", {
                    status: "error",
                    message: error.message,
                });*/
            }
        },
        async updateVertragsAutoclose({ state, commit }, { item, value }) {
            console.log(state, commit);
            console.log("updateVertragsAutoclose", item);
            const docRef = doc(firestore, `/Komfortbewirtschaftung/Stammdaten/Vertraege/${item}`);
            const data = {
                autoclose: value
            }
            try {
                await setDoc(docRef, data, { merge: true })
                // commit('showSnackbar', { message: 'Eintrag gespeichert', status: 'success' }, { root: true })
            } catch (error) {
                console.error(error);
                /*this.$store.commit("showSnackbar", {
                    status: "error",
                    message: error.message,
                });*/
            }
        },

        async updateTranchen({ state, commit }, { item, value, counter }) {
            console.log(state, commit);
            console.log("updateTrachen", item);
            let data = {
                status: "offen",
                tranchenName: ""
            };
            //const collRef=collection(firestore,`/Komfortbewirtschaftung/Stammdaten/Vertraege/${item}/tranchen`);
            //const snapshot = await collRef.get();

            console.log("counter", counter)
            if (counter < value) {
                console.log("A")
                for (let i = 1; i <= value; i++) {

                    if (i < counter) { continue }
                    const tranchenname = `Tranche ${i}`
                    data.tranchenName = tranchenname
                    const docRef = doc(firestore, `/Komfortbewirtschaftung/Stammdaten/Vertraege/${item}/tranchen/${tranchenname}`);
                    try {
                        await setDoc(docRef, data, { merge: true })
                        // commit('showSnackbar', { message: 'Eintrag gespeichert', status: 'success' }, { root: true })
                    } catch (error) {
                        console.error(error);
                        /*this.$store.commit("showSnackbar", {
                            status: "error",
                            message: error.message,
                        });*/
                    }
                }
            }
            if (counter > value) {
                console.log("B")
                for (let i = 1; i <= counter; i++) {
                    const tranchenname = `Tranche ${i}`
                    console.log(tranchenname, value);
                    if (i > value) {
                        const docRef = doc(firestore, `/Komfortbewirtschaftung/Stammdaten/Vertraege/${item}/tranchen/${tranchenname}`);
                        try {
                            await deleteDoc(docRef)
                            // commit('showSnackbar', { message: 'Eintrag gespeichert', status: 'success' }, { root: true })
                        } catch (error) {
                            console.error(error);
                            /*this.$store.commit("showSnackbar", {
                                status: "error",
                                message: error.message,
                            });*/
                        }
                    }
                }
            }

        },
        async updateZeitbereich({ state, commit }, { item, zeitbereich }) {
            console.log(state, commit);
            console.log("updateTrachen", item);
            const docRef = doc(firestore, `/Komfortbewirtschaftung/Stammdaten/Vertraege/${item}/zeitbereiche/${zeitbereich}`);
            const data = state.vertraege[item]["zeitbereiche"][zeitbereich];
            try {
                await setDoc(docRef, data)
                // commit('showSnackbar', { message: 'Eintrag gespeichert', status: 'success' }, { root: true })
            } catch (error) {
                console.error(error);
                /*this.$store.commit("showSnackbar", {
                    status: "error",
                    message: error.message,
                });*/
            }
        },
        async onKonfiguriert({ state }, { docid }) {
            state.isLoading = true;
            console.log("onKonfiguriert", docid)
            const docRef = doc(firestore, `/Komfortbewirtschaftung/Stammdaten/Vertraege/${docid}`);
            const data = { konfiguriert: true };
            try {
                await setDoc(docRef, data, { merge: true })
                // commit('showSnackbar', { message: 'Eintrag gespeichert', status: 'success' }, { root: true })
            } catch (error) {
                console.error(error);
                /*this.$store.commit("showSnackbar", {
                    status: "error",
                    message: error.message,
                });*/
            }
            state.isLoading = false;
        },
        async attachVertragZeitbereiche({ state }, { docid }) {
            return new Promise((resolve) => {
                state.isLoading = true;

                const q = query(collection(firestore, "Komfortbewirtschaftung", "Stammdaten", "Vertraege", docid, "zeitbereiche"));
                console.log("nach query")
                state.firestoreZeitbereichListener =
                    onSnapshot(q, { includeMetadataChanges: true }, (snapshot) => {
                        console.log("im snapshot", snapshot.docs)
                        //state.vertraege[docid]["zeitbereiche"].splice(0, state.data.length);
                        for (const doc of snapshot.docs) {
                            console.log(docid, doc.id)
                            if (doc.exists) {
                                const _data = doc.data();
                                Vue.set(state.vertraege[docid]["zeitbereiche"], doc.id, _data);
                            }
                        }
                        state.isLoading = false;
                        resolve(state.vertraege);
                    })
            })
        },
        async attachVertragTranchen({ state }, { docid }) {
            return new Promise((resolve) => {
                /*if (state.firestoreTranchenListener) {
                    resolve(state.value);
                    return;
                }*/
                state.isLoading = true;

                const q = query(collection(firestore, "Komfortbewirtschaftung", "Stammdaten", "Vertraege", docid, "tranchen"));
                state.firestoreTranchenListener =
                    onSnapshot(q, { includeMetadataChanges: true }, (snapshot) => {
                        //state.vertraege[docid]["zeitbereiche"].splice(0, state.data.length);
                        for (const doc of snapshot.docs) {
                            if (doc.exists) {
                                const _data = doc.data();
                                //state.vertraege[docid]["tranchen"].push({ docid: doc.id, ..._data });
                                Vue.set(state.vertraege[docid]["tranchen"], doc.id, _data);
                            }
                        }
                        state.isLoading = false;
                        resolve(state.vertraege);
                    })
            })
        },
        async holeAlleProduktnamen({ state }) {
            const prodRef = collection(firestore, "produkte");
            const prodSnapshot = await getDocs(prodRef);
            //const regex = /^.{2}B*/;
            for (const pdoc of prodSnapshot.docs) {
                //if (regex.test(doc.id)) {
                state.alleProduktnamen.push(pdoc.id);
                //}
            }
        }
    }
}