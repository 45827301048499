import { firestore } from "@/plugins/firebase";
import {
    doc,
    getDoc,
    collection,
    query,
    where,
    getDocs
} from "firebase/firestore";
import axios from "axios"

async function getPrice(product, debug = false) {
    var data = new FormData()
    data.append("apikey", process.env.VUE_APP_FIREBASE_APIKEY)
    data.append("product", product)
    if (debug) {
        data.append("debug", "true")
    }

    var config = {
        method: "post",
        url:  process.env.VUE_APP_FIREBASE_HOSTING+"/queryPrice",
        data: data
    }

    let response = await axios(config)
    return response.data
}

export default {
    state: {
        alleVertragsarten: [],
        aktuelleVertragsart: "",
        alleVertraege: [],
        alleTranchenNamen:[],
        VertraegeNachVertragsnummer:{},
        trancheninfos:{},
        produkte: {},
        preise:{},
        tabellen: {
            Header: [
                {text: "Verträge", value: "vertraege"},
                {text: "Kunde", value: "kunde"}
            ],
            Data:[],
        }
    },
    mutations: {},
    actions: {
        async holeVertragsarten({ state }) {
            const vertragsartenRef = doc(
                firestore,
                "Einstellungen",
                "Vertragsarten"
            );
            const docSnapshot = await getDoc(vertragsartenRef);
            const daten = docSnapshot.data().Vertragsarten;

            // "Tranche individual" wird hier nicht benötigt. Daher aus Array entfernen
            const index = daten.indexOf("Tranche individual");
            if (index > -1) {
                // only splice array when item is found
                daten.splice(index, 1); // 2nd parameter means remove one item only
            }
            // "Bestandsvertrag Tranche" wird hier nicht benötigt. Daher aus Array entfernen
            const index2 = daten.indexOf("Bestandsvertrag Tranche");
            if (index2 > -1) {
                // only splice array when item is found
                daten.splice(index2, 1); // 2nd parameter means remove one item only
            }

            state.alleVertragsarten = daten;
            if (state.aktuelleVertragsart == "") {
                state.aktuelleVertragsart = daten[0];
            }
        },
        async setAktuellerVertragsname({ state }, { vertragsname }) {
            let TranchenNamen={};
            let VertraegeNachVertragsnummer={};
            state.alleVertraege= [];
            state.tabellen= {
                Header: [
                    {text: "Verträge", value: "vertraege"},
                    {text: "Kunde", value: "kunde"},
                    {text: "Volumen (in MWh)", value: "volumen"}
                ],
                Data:[],
            };
            const tranchenRef = collection(firestore, "Trancheninfos");
            const collRef = collection(firestore, "Stammdaten");
            const q = query(collRef, where("vertragsart", "==", vertragsname));
            const querySnapshot = await getDocs(q);
            for (const doc of querySnapshot.docs) {
                const docdata = doc.data();
                const tranchenq = query(
                    tranchenRef,
                    where("Vertragsnummer", "==", docdata.vertragsnummer)
                );
                const tranchenSnapshot = await getDocs(tranchenq);
                let tranchen = {};
                for (const tdoc of tranchenSnapshot.docs) {
                    const data=tdoc.data();
                    state.trancheninfos[tdoc.id]=data;
                    state.produkte=data.Produkte;

                    tranchen[tdoc.id] = data.Tranchen;
                    for (const tranchenName of data.Tranchen) {
                        TranchenNamen[tranchenName]=1;
                    }
                }
                state.preise["base"] = await getPrice(
                    state.produkte.base
                )
                if (state.produkte.peak) {
                    state.preise["peak"] = await getPrice(
                        state.produkte.peak
                    )
                } else {
                    state.preise["peak"] = 0
                }
                let t = {};
                for(const tranchenzeitraum in tranchen)  {
                    const datapath = `/Tranchenorders/${tranchenzeitraum}/orders`
                    const tRef = collection(firestore, datapath)
                    const tq = query(tRef)
                    const tSnapshot = await getDocs(tq);
                    const td = [];
                    for (const tdoc of tSnapshot.docs) {
                        //const data=tdoc.data();
                        td.push(tdoc.id);
                    }
                    t[tranchenzeitraum]=td;
                }
                const vertrag = {
                    vertragsnummer: docdata.vertragsnummer,
                    name: docdata.vertragsnehmer.name,
                    tranchen: tranchen,
                    orderedtrachen: t
                };
                if (VertraegeNachVertragsnummer[docdata.vertragsnummer] === undefined) {
                    VertraegeNachVertragsnummer[docdata.vertragsnummer]=vertrag;
                }
            }
            state.VertraegeNachVertragsnummer=VertraegeNachVertragsnummer;
            state.alleTranchenNamen=Object.keys(TranchenNamen);
            for (const vertragsname of Object.keys(VertraegeNachVertragsnummer)) {
                const vertrag = VertraegeNachVertragsnummer[vertragsname];
                if (!state.alleVertraege.includes(vertrag)) {
                    state.alleVertraege.push(vertrag);
                }
            }

            // Vorbereitung der Tabellenausgabe
            for (const tranchenname of state.alleTranchenNamen) {
                let object={
                    "text": tranchenname,
                    "value": tranchenname,
                    "withCheckbox": true
                };
                state.tabellen.Header.push(object);
            }

            for (const v of state.alleVertraege) {
                const vertragsname=Object.keys(v.tranchen)[0];
                let object={
                    "vertraege": vertragsname,
                    "kunde": v.name,
                    "volumen": (parseFloat(state.trancheninfos[vertragsname].TrancheMengeinkWh)/ 1000).toFixed(2)
                };
                for (const tranchenname of state.alleTranchenNamen) {
                    if (v.tranchen[vertragsname].includes(tranchenname)) {
                        if (v.orderedtrachen && v.orderedtrachen[vertragsname] && v.orderedtrachen[vertragsname].includes(tranchenname)) {
                            object[tranchenname]="ordered";
                        } else {
                            object[tranchenname]="open";
                        }
                    } else {
                        object[tranchenname]=null;
                    }
                }
                
                state.tabellen.Data.push(object);
            }
        }
    }
};
